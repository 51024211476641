









import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";
import VueMarkdown from "vue-markdown";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class OutgrowPage extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = this.getComponentName();
  }

  mounted() {
    this.setComponentName();
  }

  @Watch("$route")
  setComponentName() {
    this.componentName = this.getComponentName();
    this.LoadContents(this.componentName);
  }

  getComponentName() {
    if (this.$route && (this.$route.params.page || this.$route.name)) {
      return this.$route.params.page || this.$route.name;
    }
    return "";
  }
}
